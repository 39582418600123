import { useLocation } from '@reach/router';
import Heading from '@legacy-vfuk/core-heading';
import Icon from '@legacy-vfuk/core-icon';
import Paragraph from '@legacy-vfuk/core-paragraph';
import axios from 'axios';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadLocalStorage } from 'usehooks-ts';

import PricingCardCarousel from '@/components/pageBuilder/Sections/PricingCardCarousel';
import { TPricingCardCarousel } from '@/components/pageBuilder/Sections/PricingCardCarousel/PricingCardCarousel.types';
import SWAvailableAppsCarouselIcons from '@/components/pageBuilder/Sections/SWAvailableAppsCarouselIcons';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';

import SWSectionFiftyFiftyBanner from '../SWSectionFiftyFiftyBanner/SWSectionFiftyFiftyBanner';
import { Icons, IconWithText, PricingContentWrapper, Title } from './SWPricingContent.styles';
import { SWPricingContentProps } from './SWPricingContent.types';
import { OPCOOptions } from '@/types/Services.types';
import handleTranslation from '@/helpers/handleTranslation';

const OPCO = process.env.GATSBY_OPCO;

const SWPricingContent: FC<SWPricingContentProps> = ({
  id,
  contentful_id,
  name,
  buttonsAction,
  buttonsAppearance,
  backgroundColor,
  backgroundImage,
  iconsWithTitle,
  includesCards,
  products,
  banners,
  bottomLink,
  subHeading,
  bottomLinkUrl,
  availableApplications,
}) => {
  const [productsWithDxlInfo, setProductsWithDxlInfo] = useState<TPricingCardCarousel['products']>([]);
  const stackableProduct = useReadLocalStorage<boolean>('vf_stackable');
  const { pathname } = useLocation();
  const BusinessBoostPage = pathname.includes('business-boost');
  const { setErrorInfo, toggleModal } = useModalContext();
  const { t } = useTranslation();
  const pricingBlackSentence = handleTranslation(
    '',
    'Ao escolher "Aderir" irá ter acesso, através de login com as suas credenciais My Vodafone, à Vodafone Cloud Store. Consulte '
  );
  const termsAndConditions = handleTranslation('', 'Termos e Condições');
  const and = handleTranslation('', 'e');
  const privacySupplement = handleTranslation('', 'Suplemento de Privacidade');
  const pricingRedSentence = handleTranslation(
    `* For this product, only one Edition can be selected and purchased (this doesn't have effect on the licence
  quantity).`,
    '*Os preços apresentados são válidos por licença e por mês, e implicam um compromisso de permanência de 24 meses.'
  );
  const pricingRedSentenceTrendMicro = handleTranslation(
    '',
    '*Apenas um tipo de edição do Trend Micro poderá ser subscrito (sem limite de número de licenças).'
  );

  useEffect(() => {
    try {
      const productsIds = products.map(product => product.productId);
      const productsWithEditions = products.map(async product => {
        product.dxlInformation = undefined;
        if (productsIds.includes(product.productId)) {
          const newProduct = await retrieveProduct(product.productId);
          if (!axios.isAxiosError(newProduct) && newProduct.status === 200 && newProduct.data && newProduct.data.id) {
            product.dxlInformation = newProduct.data.dxlInformation;
          } else {
            setErrorInfo({
              ...(axios.isAxiosError(newProduct) && {
                statusType: newProduct.response?.data?.error,
                statusCode: newProduct.response?.data?.code || newProduct.response?.status,
              }),
              requestUrl: 'catalogues',
            });
            toggleModal(true, 'Error');
          }
          return product;
        }
      });
      setProductsWithDxlInfo(productsWithEditions);
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  }, []);

  const secondSentenceTrendMicro: ReactNode = (
    <span style={{ color: '#000' }}>
      {pricingBlackSentence}
      <a
        href="https://www.vodafone.pt/info/termos-condicoes/cloud-store.html#termos-condicoes"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'blue' }}
      >
        {termsAndConditions}
      </a>{' '}
      {and}{' '}
      <a
        href="https://www.vodafone.pt/info/termos-condicoes/cloud-store.html#suplemento-privacidade"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'blue' }}
      >
        {privacySupplement}
      </a>
    </span>
  );

  return (
    <>
      <PricingContentWrapper
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        productsLength={products?.length || 0}
      >
        {name && <Title>{name}</Title>}
        <PricingCardCarousel
          id={id}
          contentful_id={contentful_id}
          name={name}
          buttonsAction={buttonsAction}
          buttonsAppearance={buttonsAppearance}
          products={productsWithDxlInfo}
          stackableProduct={stackableProduct}
          bottomLink={bottomLink}
          subHeading={subHeading}
          bottomLinkUrl={bottomLinkUrl}
          includesCards={includesCards}
        />
        {iconsWithTitle && iconsWithTitle.length > 0 && (
          <Icons>
            {iconsWithTitle.map(({ id, iconName, name }) => (
              <IconWithText key={id}>
                <Icon name={iconName} group="system" />
                <Heading>{name}</Heading>
              </IconWithText>
            ))}
          </Icons>
        )}
        {!stackableProduct && buttonsAction !== 'configureYourPack' && products?.length >= 1 && !BusinessBoostPage && (
          <Paragraph>
            {OPCO === OPCOOptions.PORTUGAL && `${pricingRedSentence}`}
            {secondSentenceTrendMicro}
          </Paragraph>
        )}
      </PricingContentWrapper>
      {availableApplications && <SWAvailableAppsCarouselIcons {...availableApplications} />}
      {banners?.map(banner => (
        <SWSectionFiftyFiftyBanner key={banner.id} {...banner} />
      ))}
    </>
  );
};

export default SWPricingContent;
